<template>
  <b-card-actions ref="card" title="" action-refresh @refresh="refresh">

    <b-tabs v-model="tabIndex">
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon"/>
          <span>  {{ $t('Profile') }}
</span>
        </template>

        <div>
          <!-- Media -->
          <b-media class="mb-2">
            <template #aside>
              <cropper
                  id="profile_image"
                  ref="cropper"
                  :src="url"
                  class="cropper h-24 w-24"
                  v-if="showCropper"
                  :stencil-props="{
		aspectRatio: 10/10
	}"
              />
              <img v-else :src="url" class="mr-8 rounded h-24 w-24 profile-pic"/>
            </template>
            <h4 class="mb-1">
              {{ employee.first_name + ' ' + employee.last_name }}
            </h4>
            <div class="d-flex flex-wrap">

              <input
                  type="file"
                  ref="refInputEl"
                  class="hidden"
                  accept="image/*"
                  name="profile_image"
                  id="profile_image"
                  @change="selectedFile"
              />

              <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
                  v-if="!showCropper"
                  :disabled="allEditDisabled"
              >

                <span class="d-none d-sm-inline">{{ $t('Upload image') }}</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                  variant="primary"
                  @click="cropImage()"
                  v-else
              >

                <span class="d-none d-sm-inline">{{ $t('Crop') }}</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  :disabled="allEditDisabled"

                  @click="deleteImage()"
              >
                <span class="d-none d-sm-inline">{{ $t('Delete') }}</span>
                <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"

                />
              </b-button>
            </div>
          </b-media>

          <validation-observer
              ref="validationRules"
              tag="form"
          >
            <!-- User Info: Input Fields -->
            <b-form>
              <b-row>

                <!-- Field: First Name -->
                <b-col
                    cols="12"
                    md="4"
                >


                  <b-form-group
                      label-for="first_name"

                  >

                    <validation-provider
                        #default="{ errors }"
                        name="first_name"
                        rules="required"
                        :custom-messages="customMessages"
                        :state="errors.length > 0 ? false:null"

                    >
                      <label v-html="getRequiredLabel('First Name')"></label>
                      <b-form-input
                          :disabled="allEditDisabled"
                          id="first_name"
                          v-model="employee.first_name"
                          @keyup="employee.first_name=titleCase(employee.first_name)"

                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>

                  </b-form-group>
                </b-col>

                <!-- Field: Last Name -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label-for="last_name"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="last_name"
                        rules="required"
                        :custom-messages="customMessages"
                        :state="errors.length > 0 ? false:null"

                    >
                      <label v-html="getRequiredLabel('Last Name')"></label>

                      <b-form-input
                          :disabled="allEditDisabled"
                          id="last_name"
                          v-model="employee.last_name"
                          @keyup="employee.last_name=titleCase(employee.last_name)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>

                  </b-form-group>
                </b-col>

                <!-- Field: Department -->
                <b-col
                    cols="12"
                    md="10"
                >
                  <b-form-group
                      :label="$t('Department')"
                      label-for="department"
                  >
                    <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :text="currentEmployeeDepartment.name"
                        variant="outline-primary"
                        block
                        :disabled="allEditDisabled"
                    >
                      <b-dropdown-item
                          v-for="employee_department in employee_departments"
                          :key="employee_department.id"
                          @click="setDepartment(employee_department)"
                      >
                        <span class="ml-50">{{ employee_department.name }}</span>
                      </b-dropdown-item>

                    </b-dropdown>
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="2"
                >
                  <b-form-group
                      :label="$t('New department')"
                      label-for="department"
                  >
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-department
                        variant="outline-primary"
                        :disabled="allEditDisabled"
                    >
                      {{ $t('Create') }}
                    </b-button>


                  </b-form-group>
                </b-col>


                <!-- Field: Role -->
                <b-col
                    cols="12"
                    md="10"
                >
                  <b-form-group
                      :label="$t('Role')"
                      label-for="user-role"
                  >
                    <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :text="currentEmployeeRole.name"
                        variant="outline-primary"
                        block
                        :disabled="allEditDisabled"
                    >
                      <b-dropdown-item
                          v-for="employee_role in employee_roles"
                          :key="employee_role.id"
                          @click="setRole(employee_role)"
                      >
                        <span class="ml-50">{{ employee_role.name }}</span>
                      </b-dropdown-item>

                    </b-dropdown>
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="2"
                >
                  <b-form-group
                      :label="$t('New role')"
                      label-for="new_role"
                  >
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-role
                        variant="outline-primary"
                        :disabled="allEditDisabled"
                    >
                      {{ $t('Create') }}
                    </b-button>
                  </b-form-group>
                </b-col>


                <!-- Field: Fiscal Code -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Fiscal Code')"
                      label-for="fiscal_code"
                  >
                    <b-form-input
                        id="fiscal_code"
                        v-model="employee.fiscal_code"
                        type="text"
                        :disabled="allEditDisabled"
                    />
                  </b-form-group>
                </b-col>
                <!-- Field: Birth Place -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Birth Place')"
                      label-for="birth_place"
                  >
                    <b-form-input
                        id="birth_place"
                        v-model="employee.birth_place"
                        type="text"
                        :disabled="allEditDisabled"
                        @keyup="employee.birth_place=titleCase(employee.birth_place)"
                    />
                  </b-form-group>
                </b-col>
                <!-- Field: Birth Day -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Birthday')"
                      label-for="birthday"
                  >
                    <flat-pickr
                        id="birthday"
                        v-model="employee.birthday"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: Birth Day -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Assignments')"
                      label-for="assigments"
                  >
                    <v-select
                        v-model="assignment_selected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="assignments"
                        :options="assignment_options"
                        id="assignments"
                        :disabled="allEditDisabled"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: Protected Category -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Protected Category')"
                      label-for="protected_category"
                  >
                    <b-form-checkbox
                        v-model="protected_category"
                        name="protected_category"
                        id="protected_category"
                        switch
                        inline
                        class="mt-06"
                        :disabled="allEditDisabled"
                    >
                      {{ $t('Protected Category') }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- Field: Phone -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Phone')"
                      label-for="phone"
                  >
                    <b-form-input
                        id="phone"
                        v-model="employee.phone"
                        type="text"
                        :disabled="allEditDisabled"
                    />
                  </b-form-group>
                </b-col>
                <!-- Field: Email -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Email')"
                      label-for="email"
                  >
                    <b-form-input
                        id="email"
                        v-model="employee.email"
                        type="email"
                        :disabled="allEditDisabled"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: Address -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Address')"
                      label-for="address"
                  >
                    <b-form-textarea
                        rows="3"
                        id="address"
                        name="address"
                        v-model="employee.address"
                        :disabled="allEditDisabled"
                    />
                  </b-form-group>
                </b-col>
                <!-- Field: Residence -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Residence')"
                      label-for="residence"
                  >
                    <b-form-textarea
                        rows="3"
                        id="residence"
                        name="residence"
                        v-model="employee.residence"
                        :disabled="allEditDisabled"
                    />
                  </b-form-group>
                </b-col>


                <!-- Field: Terminated -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      :label="$t('Status')"
                      label-for="terminated"
                  >
                    <b-form-checkbox
                        v-model="terminated"
                        name="terminated"
                        id="terminated"
                        switch
                        inline
                        class="mt-06 custom-control-danger"
                        :disabled="allEditDisabled"

                    >
                      {{ terminated_label }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-form>

          </validation-observer>

          <!-- Action Buttons -->
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="download_badge()"
              style="min-width: 135px"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"

          >
            <feather-icon
                icon="PrinterIcon"
                class="mr-50"
            />
            <span class="align-middle">{{ $t("Print badge") }}</span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="download_dpi()"
              style="min-width: 135px"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"

          >
            <feather-icon
                icon="DownloadIcon"
                class="mr-50"
            />
            <span class="align-middle">{{ $t("Print DPI Declaration") }}</span>
          </b-button>
          <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="handleSubmit()"
              :disabled="allEditDisabled"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="back($event)"
          >
            {{ $t('Cancel') }}


          </b-button>
        </div>


      </b-tab>
      <b-tab ref='courses_tab' :disabled="!courses_enabled">
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span>            {{ $t('Courses') }}</span>
        </template>

        <courses-list-component/>
      </b-tab>
      <b-tab ref='medical_tab' :disabled="!medical_enabled">
        <template #title>
          <feather-icon icon="ThermometerIcon"/>
          <span>{{ $t('Medical Visits') }}</span>
        </template>
        <medical-checkups-list-component/>

      </b-tab>

      <b-tab ref='documents_tab' :disabled="!medical_enabled">
        <template #title>
          <feather-icon icon="FileIcon"/>
          <span>{{ $t('Documents and qualifications') }}</span>
        </template>
        <employee-documents-list-component/>

      </b-tab>

    </b-tabs>
    <b-modal
        id="modal-department"
        cancel-variant="outline-secondary"
        :ok-title="$t('Save')"
        ok-s
        :cancel-title="$t('Cancel')"
        centered
        :title="$t('New department')"
        @ok="createDepartment"
    >
      <b-form>
        <b-form-group>
          <label for="new_department">{{ $t('New department') }}:</label>
          <b-form-input
              id="new_department"
              type="text"
              v-model="new_department_name"
              :placeholder="$t('Name')"
          />
        </b-form-group>

      </b-form>
    </b-modal>

    <b-modal
        id="modal-role"
        cancel-variant="outline-secondary"
        :ok-title="$t('Save')"
        :cancel-title="$t('Cancel')"
        centered
        :title="$t('New role')"
        @ok="createRole"
    >
      <b-form>
        <b-form-group>
          <label for="new_role">{{ $t('New role') }}:</label>
          <b-form-input
              id="new_role"
              type="text"
              v-model="new_role_name"
              :placeholder="$t('Name')"
          />
        </b-form-group>

      </b-form>
    </b-modal>
  </b-card-actions>


</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BCardText,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BFormTextarea,
  BTable,
  BTabs,
  BTab,
  BCardHeader,
  BDropdown,
  BDropdownItem,

  BCardTitle, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EmployeeService from "@/basic/employeeService";
import useUsersList from "@/views/apps/user/users-list/useUsersList";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import vSelect from 'vue-select'
import CoursesListComponent from "@/views/table/vue-good-table/CoursesListComponent";
import MedicalCheckupsListComponent from "@/views/table/vue-good-table/MedicalCheckupsListComponent";
import EmployeeDocumentsListComponent from "@/views/table/vue-good-table/EmployeeDocumentsListComponent";
import UserService from "@/basic/userService";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ability from "@/libs/acl/ability";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {environment} from "@/auth/environment";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BAvatar,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BMedia,
    BTabs,
    BDropdown,
    BTab,
    BDropdownItem,
    flatPickr,
    Spacer,
    vSelect,
    CoursesListComponent,
    MedicalCheckupsListComponent,
    EmployeeDocumentsListComponent,
    BFormFile,
    Cropper,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      employee: {
        first_name: "",
        last_name: "",
        employee_id: "",
        office: "",
        photo: "",
        birth_place: "",
        birthday: "",
        fiscal_code: "",
        phone: "",
        email: "",
        address: "",
        residence: "",

      },
      customMessages: {
        required: i18n.t('This field is required'),
      },
      errors: [],
      company_users: [],
      permission_fields: [{key: 'name', label: i18n.t('Name'),}, {key: 'read', label: i18n.t('Read'),}, {
        key: 'write',
        label: i18n.t('Write')
      }],
      permissionsData: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: false,
      allEditDisabled: !ability.can("manage", "Basic HR"),
      department_new_id: 0,
      role_new_id: 0,
      employee_roles: [{id: 1, name: ""}],
      employee_departments: [{id: 1, name: ""}],
      dateDefault: null,
      assignment_options: ['a', 'b', 'c'],
      assignment_selected: [],
      protected_category: false,
      terminated: false,
      courses_enabled: false,
      medical_enabled: false,
      avatar_file: null,
      filedata: null,
      images: null,
      url: require("@/assets/images/avatars/avatar.png"),
      variant: "light",
      opacity: 0.85,
      blur: "2px",
      crop: {
        src: "",
        type: "image/jpg",
      },
      file_name: "",
      showCropper: false,
      tab: this.$route.params.tab ? this.$route.params.tab : "main",
      tabIndex: 1,
      new_department_name: "",
      new_role_name: "",
      flatPickrConfig: {
        dateFormat: 'd/m/Y',
      },
    }
  },
  computed: {
    currentEmployeeRole() {
      if (this.employee && this.employee_roles && this.role_new_id) {
        let role = this.employee_roles.find(l => l.id == this.role_new_id);
        console.log("currentEmployeeRole section", role)
        return role
        //return {id: "1", name: "alfa"}
      }
      return {id: "-11", name: "Ruolo"}
    },
    terminated_label() {

      return (this.terminated) ? i18n.t('Terminated Employee') : i18n.t('Not terminated');
    },
    currentEmployeeDepartment() {
      if (this.employee && this.employee_departments && this.department_new_id) {
        let department = this.employee_departments.find(l => l.id == this.department_new_id);
        console.log("currentEmployeeDepartment employee_departments", department)
        return department
        //return {id: "1", name: "alfa"}
      }
      return {id: "-11", name: i18n.t('Department')}
    }
  },
  methods: {
    getRequiredLabel(field) {
      return '<span class="text-danger">*</span> ' + i18n.t(field);
    },
    refresh() {

      console.log('tab', this.tab)
      switch (this.tab) {
        case "main":
          this.tabIndex = 1;
          break;
        case "courses":
          this.tabIndex = 2;
          break;
        case "medical":
          this.tabIndex = 3;
          break;
        case "documents":
          this.tabIndex = 4;
          break;
        default:
          this.tabIndex = 1;
          break;
      }

      console.log('tabIndex', this.tabIndex)


      const employeeService = new EmployeeService(useJwt)

      let employee_id = this.$route.params.id;
      this.$refs["card"].showLoading = true


      let company_id = store.state.userCompany.id;


      employeeService.employee(employee_id, company_id).then(response => {

        this.employee_roles = response.data.data.employee_roles;
        this.employee_departments = response.data.data.employee_departments;

        if (response.data.data.employee) {
          this.employee = response.data.data.employee
          if (this.employee.photo)
            this.url = this.employee.photo
          this.assignment_options = response.data.data.assignments.map(function (value, index) {
            return value.name
          })
          if (this.employee.terminated_at) {
            this.terminated = true;
          }
          this.assignment_selected = this.employee.assignments.map(function (value, index) {
            return value.name
          })

          if (this.employee.protected_category === 1)
            this.protected_category = true
          this.role_new_id = this.employee.employee_role_id;
          this.department_new_id = this.employee.employee_department_id;

          this.courses_enabled = true;
          this.medical_enabled = true;
        } else {
          console.log('employee not found')
        }

        console.log('tabIndex s', this.tabIndex)

        console.log('data', response.data.data)
        console.log('employee_roles', this.employee_roles)
        this.$refs["card"].showLoading = false

      })


    },
    async back(e) {
      this.$router.push({name: 'employees'})

    },
    titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    },

    selectedFile(event) {
      this.success = "";
      this.error = "";
      var filePath = event.target.files[0].name;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(filePath)) {
        this.error = this.$t("FileUploadError");
        this.$toasted.show(this.error, {
          className: "top-alert",
          position: "top-center",
          type: "error",
          duration: 2000,
        });
        $("#profile_image").val("");
      } else {
        this.images = event.target.files[0];
        this.file_name = this.images.name;
        this.url = URL.createObjectURL(this.images);
        this.crop.src = URL.createObjectURL(this.images);
        this.showCropper = true;
      }
    },

    handleUpload() {
      console.log("UPLOAD 1")


      console.log("UPLOAD 2")

      let filevalue = this.avatar_file;
      console.log('handleUpload filevalue', filevalue)

      var filePath = filevalue.name;
      console.log('handleUpload filePath', filevalue.name)


      let reader = new FileReader();

      reader.onload = (e) => {
        console.log('readAsDataURL', e.target.result);
        this.filedata = e.target.result
        const data_upload = {
          filedata: this.filedata,
          filename: filePath
        };
        console.log("UPLOAD 2", filedata)

        this.employee.photo = filedata;

      };

      reader.readAsDataURL(this.avatar_file);


    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      let obj = this;
      this.url = result.canvas.toDataURL(this.crop.type);
      console.log('url', this.url)
      this.urltoFile(
          result.canvas.toDataURL(this.crop.type),
          this.file_name,
          "image/*"
      ).then(function (file) {
        console.log("rahul--", file);
        obj.images = file;

      });
      this.images = obj.images;
      this.showCropper = false;
      console.log("rahul--2", this.images);

    },

    deleteImage() {
      console.log('deleteImage')
      this.images = "";
      this.showCropper = false;
      this.url = require("@/assets/images/avatars/avatar.png");


    },

    urltoFile(url, filename, mimeType) {
      return fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], filename, {type: mimeType});
          });
    },

    setRole(role) {
      this.role_new_id = role.id;
    },
    createRole() {
      console.log('new role name', this.new_role_name)
      const employeeService = new EmployeeService(useJwt)
      let company_id = store.state.userCompany.id;
      this.$refs["card"].showLoading = true

      const data = {
        employeeRole: {name: this.new_role_name, company_id: company_id}
      };

      employeeService.update_role('new', data).then(response => {
        console.log("response", response)
        this.employee_roles = response.data.data.roles;
        this.role_new_id = response.data.data.role.id;
        this.$refs["card"].showLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('Role'),
            icon: 'AlertIcon',
            variant: 'success',
            text: i18n.t('Role saved with success'),
          },
        })
      })
      this.new_role_name = ""
    },
    createDepartment() {
      console.log('new department name', this.new_department_name)
      const employeeService = new EmployeeService(useJwt)
      let company_id = store.state.userCompany.id;
      this.$refs["card"].showLoading = true

      const data = {
        employeeDepartment: {name: this.new_department_name, company_id: company_id}
      };

      employeeService.update_department('new', data).then(response => {
        console.log("response", response)
        this.employee_departments = response.data.data.departments;
        this.department_new_id = response.data.data.department.id;
//          this.refresh();
        this.$refs["card"].showLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('Department'),
            icon: 'AlertIcon',
            variant: 'success',
            text: i18n.t('Department saved with success'),
          },
        })
      })
      this.new_department_name = ""
    },
    setDepartment(department) {
      this.department_new_id = department.id;
    },
    download_badge() {
      let employee_id = this.$route.params.id;

      const link = document.createElement("a");
      link.href = environment.baseURL + "/dashboard/downloadEmployeeBadge/" + employee_id
      link.download = this.employee.first_name + "_" + this.employee.last_name + ".pdf";
      link.click();
      URL.revokeObjectURL(link.href);

    },
    download_dpi() {
      let employee_id = this.$route.params.id;

      const link = document.createElement("a");
      link.target = "_blank";
      link.href = environment.baseURL + "/dashboard/downloadEmployeeDPI/" + employee_id
      link.download = this.employee.first_name + "_" + this.employee.last_name + "_dpi.pdf";
      link.click();
      URL.revokeObjectURL(link.href);

    },

    async handleSubmit(e) {
      this.$refs.validationRules.validate().then(success => {
            if (success) {
              console.log("VALIDATION OK")

              const employeeService = new EmployeeService(useJwt)
              console.log("inputImageRenderer", this.inputImageRenderer)
              this.submitted = true;
              //const valid = await this.$validator.validate();
              const valid = true;

              if (!valid) {
                this.$vs.notify({
                  title: this.$t("create_admin.FailedToCreateAdmin"),
                  text: this.$t("create_admin.ConfirmPasswordFailed"),
                  color: "danger",
                  position: "top-center"
                });
                this.submitted = false;
                return false;
              }

              this.$refs["card"].showLoading = true
              this.employee.protected_category = this.protected_category
              this.employee.photo = this.url

              const data = {
                employee: this.employee,
                company: this.userCompany,
                assignment_selected: this.assignment_selected,
                employee_department: this.department_new_id,
                employee_role: this.role_new_id,
                photo_file_name: this.file_name,
                terminated: this.terminated,
              };


              try {
                console.log('data', data)
                let employee_id = this.$route.params.id;

                employeeService.update_employee(employee_id, data).then(response => {
                  console.log("response", response)
//          this.refresh();
                  this.$refs["card"].showLoading = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Employee'),
                      icon: 'AlertIcon',
                      variant: 'success',
                      text: i18n.t('Employee saved with success'),
                    },
                  })
                  this.$router.push({name: 'employees'})
                })

                const response = {status: 200, data: {id: 1}}


              } catch (error) {

              }
              this.submitted = true;
            } else {
              console.log("VALIDATION KO")

              this.submitted = false;

            }
          }
      )
    },

  },
  mounted() {
    this.refresh()
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()


    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.avatar = base64
      console.log("avatar", props.avatar)
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
